export const jitoTipWallet = 'ADaUMid9yfUytqMBgopwjb2DTLSokTSzL1zt6iGPaS49';
export const PUMP_FUN_PROGRAM_ID = '6EF8rrecthR5Dkzon8Nwu78hRvfCKubJ14M5uBEwF6P';
export const FEE_RECEIVER = '3x4KNefSyM3vXdRvs8AFQxrczDSWwkhodCUoGt91qNdj';
export const MPL_TOKEN_METADATA = 'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s';
export const RENT_PROGRAM = 'SysvarRent111111111111111111111111111111111';
export const PUMP_MINT_AUTHORITY = 'TSLvdd1pWpHVjahSpsvCXUbgwsL3JAcvokwaKt1eokM';
export const PUMP_GLOBAL = '4wTV1YmiEkRvAtNtsSGPtUrqRYQMe5SKy2uB4Jjaxnjf';
export const PUMP_DEX_CURVE_PERCENT = 20.6;

export const coingeckoApiSolUrl = `https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd`;

import {
    LIQUIDITY_STATE_LAYOUT_V4,
    Liquidity,
    LiquidityPoolKeysV4,
    MARKET_STATE_LAYOUT_V3,
    Market,
    SPL_MINT_LAYOUT,
} from '@raydium-io/raydium-sdk';
import { Connection, PublicKey } from '@solana/web3.js';

export async function formatAmmKeysById(connection: Connection, id: PublicKey): Promise<LiquidityPoolKeysV4> {
    const account = await connection.getAccountInfo(id, { commitment: 'processed' });
    if (account === null) throw Error(' get id info error ');
    const info = LIQUIDITY_STATE_LAYOUT_V4.decode(account.data);

    const marketId = info.marketId;
    const marketAccount = await connection.getAccountInfo(marketId, { commitment: 'processed' });
    if (marketAccount === null) throw Error(' get market info error');
    const marketInfo = MARKET_STATE_LAYOUT_V3.decode(marketAccount.data);

    const lpMint = info.lpMint;
    const lpMintAccount = await connection.getAccountInfo(lpMint, { commitment: 'processed' });
    if (lpMintAccount === null) throw Error(' get lp mint info error');
    const lpMintInfo = SPL_MINT_LAYOUT.decode(lpMintAccount.data);

    return {
        id,
        baseMint: info.baseMint,
        quoteMint: info.quoteMint,
        lpMint: info.lpMint,
        baseDecimals: info.baseDecimal.toNumber(),
        quoteDecimals: info.quoteDecimal.toNumber(),
        lpDecimals: lpMintInfo.decimals,
        version: 4,
        programId: account.owner,
        authority: Liquidity.getAssociatedAuthority({ programId: account.owner }).publicKey,
        openOrders: info.openOrders,
        targetOrders: info.targetOrders,
        baseVault: info.baseVault,
        quoteVault: info.quoteVault,
        withdrawQueue: info.withdrawQueue,
        lpVault: info.lpVault,
        marketVersion: 4,
        marketProgramId: info.marketProgramId,
        marketId: info.marketId,
        marketAuthority: Market.getAssociatedAuthority({ programId: info.marketProgramId, marketId: info.marketId }).publicKey,
        marketBaseVault: marketInfo.baseVault,
        marketQuoteVault: marketInfo.quoteVault,
        marketBids: marketInfo.bids,
        marketAsks: marketInfo.asks,
        marketEventQueue: marketInfo.eventQueue,
    } as unknown as LiquidityPoolKeysV4;
}

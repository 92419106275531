import { Dispatch, SetStateAction, ChangeEvent, useEffect } from 'react';
import { Connection } from '@solana/web3.js';
import { DefaultSettingsType } from '../utils/types';

import SettingsCSS from '../styles/Settings.module.css';

export function Settings({
    nodeMode,
    setNodeMode,
    setCustomHttp,
    setCustomWs,
    setJitoBlockengine,
    setDefaultSellPercent,
    availableNodes,
    customHttp,
    customWs,
    jitoBlockengine,
    availableJitoBlockengines,
    defaultSellPercent,
    setConnection,
    rpcTxEnabled,
    setRpcTxEnabled,
    rpcTxPriorityFee,
    setRpcTxPriorityFee,
    jitoEnabled,
    setJitoEnabled,
    jitoPriorityFee,
    setJitoPriorityFee,
    defaultSettings,
}: {
    nodeMode: string;
    setNodeMode: React.Dispatch<React.SetStateAction<string>>;
    setCustomHttp: React.Dispatch<React.SetStateAction<string>>;
    setCustomWs: React.Dispatch<React.SetStateAction<string>>;
    setJitoBlockengine: React.Dispatch<React.SetStateAction<string>>;
    setDefaultSellPercent: React.Dispatch<React.SetStateAction<number>>;
    availableNodes: string[];
    customHttp: string;
    customWs: string;
    jitoBlockengine: string;
    availableJitoBlockengines: string[];
    defaultSellPercent: number;
    setConnection: React.Dispatch<React.SetStateAction<Connection>>;
    rpcTxEnabled: boolean;
    setRpcTxEnabled: Dispatch<SetStateAction<boolean>>;
    rpcTxPriorityFee: number;
    setRpcTxPriorityFee: Dispatch<SetStateAction<number>>;
    jitoEnabled: boolean;
    setJitoEnabled: Dispatch<SetStateAction<boolean>>;
    jitoPriorityFee: number;
    setJitoPriorityFee: Dispatch<SetStateAction<number>>;
    defaultSettings: DefaultSettingsType;
}) {
    const nodeModeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setNodeMode(value);
        localStorage.setItem('nodeMode', value);
    };

    const customHttpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const httpUrl = event.target.value;
        setCustomHttp(httpUrl);
        localStorage.setItem('customHttp', httpUrl);
        const connection = new Connection(httpUrl, { wsEndpoint: customWs });
        setConnection(connection);
    };

    const customWsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const wsUrl = event.target.value;
        setCustomWs(wsUrl);
        localStorage.setItem('customWs', wsUrl);
        const connection = new Connection(customHttp, { wsEndpoint: wsUrl });
        setConnection(connection);
    };

    const jitoBlockengineChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setJitoBlockengine(value);
        localStorage.setItem('jitoBlockengine', value);
    };

    const defaultSellPercentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setDefaultSellPercent(Number(value));
        localStorage.setItem('defaultSellPercent', value);
    };

    const rpcTxEnabledChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        setRpcTxEnabled(value);
        localStorage.setItem('rpcTxEnabled', value.toString());
    };

    const rpcTxPriorityFeeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setRpcTxPriorityFee(Number(value));
        localStorage.setItem('rpcTxPriorityFee', value);
    };

    const jitoEnabledChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        setJitoEnabled(value);
        localStorage.setItem('jitoEnabled', value.toString());
    };

    const jitoPriorityFeeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setJitoPriorityFee(Number(value));
        localStorage.setItem('jitoPriorityFee', value);
    };

    useEffect(() => {
        const savedNodeMode = localStorage.getItem('nodeMode');
        if (savedNodeMode) setNodeMode(savedNodeMode);

        const savedCustomHttp = localStorage.getItem('customHttp');
        if (savedCustomHttp) setCustomHttp(savedCustomHttp);

        const savedCustomWs = localStorage.getItem('customWs');
        if (savedCustomWs) setCustomWs(savedCustomWs);

        const savedJitoBlockengine = localStorage.getItem('jitoBlockengine');
        if (savedJitoBlockengine) setJitoBlockengine(savedJitoBlockengine);

        const savedDefaultSellPercent = localStorage.getItem('defaultSellPercent');
        if (savedDefaultSellPercent) setDefaultSellPercent(Number(savedDefaultSellPercent));

        const savedRpcTxEnabled = localStorage.getItem('rpcTxEnabled');
        if (savedRpcTxEnabled) setRpcTxEnabled(savedRpcTxEnabled === 'true');

        const savedRpcTxPriorityFee = localStorage.getItem('rpcTxPriorityFee');
        if (savedRpcTxPriorityFee) setRpcTxPriorityFee(Number(savedRpcTxPriorityFee));

        const savedJitoEnabled = localStorage.getItem('jitoEnabled');
        if (savedJitoEnabled) setJitoEnabled(savedJitoEnabled === 'true');

        const savedJitoPriorityFee = localStorage.getItem('jitoPriorityFee');
        if (savedJitoPriorityFee) setJitoPriorityFee(Number(savedJitoPriorityFee));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const restoreDefaults = () => {
        setNodeMode(defaultSettings.nodeMode);
        setCustomHttp(defaultSettings.customHttp);
        setCustomWs(defaultSettings.customWs);
        setJitoBlockengine(defaultSettings.jitoBlockengine);
        setDefaultSellPercent(defaultSettings.defaultSellPercent);
        setRpcTxEnabled(defaultSettings.rpcTxEnabled);
        setRpcTxPriorityFee(defaultSettings.rpcTxPriorityFee);
        setJitoEnabled(defaultSettings.jitoEnabled);
        setJitoPriorityFee(defaultSettings.jitoPriorityFee);

        localStorage.setItem('nodeMode', defaultSettings.nodeMode);
        localStorage.setItem('customHttp', defaultSettings.customHttp);
        localStorage.setItem('customWs', defaultSettings.customWs);
        localStorage.setItem('jitoBlockengine', defaultSettings.jitoBlockengine);
        localStorage.setItem('defaultSellPercent', defaultSettings.defaultSellPercent.toString());
        localStorage.setItem('rpcTxEnabled', defaultSettings.rpcTxEnabled.toString());
        localStorage.setItem('rpcTxPriorityFee', defaultSettings.rpcTxPriorityFee.toString());
        localStorage.setItem('jitoEnabled', defaultSettings.jitoEnabled.toString());
        localStorage.setItem('jitoPriorityFee', defaultSettings.jitoPriorityFee.toString());
    };

    return (
        <section className={SettingsCSS.container}>
            <div className={SettingsCSS.settingsWrap}>
                <div className={SettingsCSS.restoreButtonWrap}>
                    <label>
                        <b>Node</b>
                        <select value={nodeMode} onChange={nodeModeChange}>
                            {availableNodes.map((node, index) => (
                                <option value={node} key={index}>
                                    {node}
                                </option>
                            ))}
                        </select>
                    </label>
                    <button className={SettingsCSS.restoreButton} onClick={restoreDefaults}>
                        <span>Restore Defaults</span>
                    </button>
                </div>
                <label>
                    <b>Custom HTTP</b>
                    <input className={SettingsCSS.inputUrl} type='text' value={customHttp} onChange={customHttpChange} />
                </label>
                <label>
                    <b>Custom WS</b>
                    <input className={SettingsCSS.inputUrl} type='text' value={customWs} onChange={customWsChange} />
                </label>
                <label>
                    <b>JITO Blockengine</b>
                    <select value={jitoBlockengine} onChange={jitoBlockengineChange}>
                        {availableJitoBlockengines.map((jitoBlockengine, index) => (
                            <option value={jitoBlockengine} key={index}>
                                {jitoBlockengine}
                            </option>
                        ))}
                    </select>
                </label>
            </div>
            <div className={SettingsCSS.settingsWrap}>
                <label className={SettingsCSS.inputContainer}>
                    <b>Custom Sell Default Percent</b>
                    <input
                        className={SettingsCSS.inputPercent}
                        type='number'
                        value={defaultSellPercent}
                        onChange={defaultSellPercentChange}
                    />
                </label>
                <div className={SettingsCSS.settingsContainer}>
                    <label className={SettingsCSS.settingsCheckbox}>
                        <input type='checkbox' checked={rpcTxEnabled} onChange={rpcTxEnabledChange} />
                        <b>RPC TX</b>
                    </label>
                    <label>
                        <b>Priority Fee Sol</b>
                        <input
                            className={SettingsCSS.inputPercent}
                            type='number'
                            value={rpcTxPriorityFee}
                            onChange={rpcTxPriorityFeeChange}
                        />
                    </label>
                </div>
                <div className={SettingsCSS.settingsContainer}>
                    <label className={SettingsCSS.settingsCheckbox}>
                        <input type='checkbox' checked={jitoEnabled} onChange={jitoEnabledChange} />
                        <b>JITO</b>
                    </label>
                    <label>
                        <b>Priority Fee Sol</b>
                        <input
                            className={SettingsCSS.inputPercent}
                            type='number'
                            value={jitoPriorityFee}
                            onChange={jitoPriorityFeeChange}
                        />
                    </label>
                </div>
            </div>
        </section>
    );
}

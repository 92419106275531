export function countRoles(roles: string[]): string[] {
    const roleCounts: { [key: string]: number } = {};
    const result: string[] = [];

    roles.forEach((role) => {
        const capitalRole = role.charAt(0).toUpperCase() + role.slice(1);
        if (roleCounts[capitalRole]) {
            roleCounts[capitalRole] += 1;
        } else {
            roleCounts[capitalRole] = 1;
        }
        result.push(
            roleCounts[capitalRole] === 1 && roles.indexOf(role) === roles.lastIndexOf(role)
                ? capitalRole
                : `${capitalRole}${roleCounts[capitalRole]}`,
        );
    });

    return result;
}

export function sliceWalletAddress(address: string, sliceLength: number): string {
    return `${address.slice(0, sliceLength)}...${address.slice(address.length - sliceLength)}`;
}

import { fetchDigitalAsset } from '@metaplex-foundation/mpl-token-metadata';
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { publicKey } from '@metaplex-foundation/umi';
import { Connection } from '@solana/web3.js';

export const useMetaplex = () => {
    async function getDigitalAsset(connection: Connection, mint: string) {
        try {
            const umi = createUmi(connection);
            return await fetchDigitalAsset(umi, publicKey(mint), { commitment: 'processed' });
        } catch (error) {
            console.error(`Failed to fetch digital asset for mint ${mint}: ${error}`);
            throw new Error(`Invalid mint public key! ${error}`);
        }
    }

    async function getNameAndSymbol(connection: Connection, mint: string): Promise<[string, string] | null> {
        try {
            const asset = await getDigitalAsset(connection, mint);
            const { name, symbol } = asset?.metadata || {};
            return [name, symbol];
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async function getTokenSupply(connection: Connection, mint: string): Promise<number | null> {
        try {
            const asset = await getDigitalAsset(connection, mint);
            const { supply, decimals } = asset?.mint || {};
            return Number(supply) / 10 ** decimals;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    return { getNameAndSymbol, getTokenSupply };
};

import { Connection, Keypair, LAMPORTS_PER_SOL, PublicKey, SystemProgram, TransactionInstruction } from '@solana/web3.js';
import { MPL_TOKEN_METADATA, PUMP_FUN_PROGRAM_ID, RENT_PROGRAM } from '../utils/constants';
import { AnchorProvider, BN, Program, Wallet } from '@coral-xyz/anchor';
import pumpProgramIdl from '../utils/pump-program-idl';
import { TOKEN_PROGRAM_ID } from '@coral-xyz/anchor/dist/cjs/utils/token';
import {
    ASSOCIATED_TOKEN_PROGRAM_ID,
    createAssociatedTokenAccountInstruction,
    getAssociatedTokenAddressSync,
    TokenAccountNotFoundError,
    TokenInvalidAccountOwnerError,
    getAccount,
} from '@solana/spl-token';
import { PumpFun } from '../utils';

export const usePump = () => {
    /**
     * Get Details from Token Mint with heroku api
     * @param tokenMintAddress
     * @returns bondingCurve
     * @returns associatedBondingCurve
     */
    function getDetailsFromTokenMint(connection: Connection, tokenMintAddress: string) {
        // let before: string | undefined = undefined;
        // let firstTx = '';

        // const limit = 1000;
        // while (true) {
        //     console.log('before', before);
        //     const signatures = (await connection.getSignaturesForAddress(
        //         new PublicKey(tokenMintAddress),
        //         { limit: limit, until: undefined, before: before },
        //         'confirmed',
        //     )) as ConfirmedSignatureInfo[];
        //     console.log('after');
        //     console.log('signatures', signatures);
        //     if (signatures.length > 0) before = signatures[signatures.length - 1].signature;
        //     if (signatures.length < limit) {
        //         firstTx = before!;
        //         break;
        //     }
        //     console.log('before', before);
        //     await new Promise((resolve) => setTimeout(resolve, 100));
        // }

        // const transaction = await connection.getTransaction(firstTx, {
        //     commitment: 'confirmed',
        //     maxSupportedTransactionVersion: 0,
        // });
        // const keys = transaction!.transaction.message.getAccountKeys().staticAccountKeys.map((key) => key.toBase58());
        // const bundingCurve = keys[3];
        // const associatedBundingCurve = keys[4];
        // console.log('bundingCurve', bundingCurve);
        // console.log('associatedBundingCurve', associatedBundingCurve);
        const bondingCurve = PublicKey.findProgramAddressSync(
            [Buffer.from('bonding-curve'), new PublicKey(tokenMintAddress).toBuffer()],
            new PublicKey(PUMP_FUN_PROGRAM_ID),
        )[0];
        const associatedBundingCurve = PublicKey.findProgramAddressSync(
            [bondingCurve.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), new PublicKey(tokenMintAddress).toBuffer()],
            new PublicKey(ASSOCIATED_TOKEN_PROGRAM_ID),
        )[0];

        return { bondingCurve: bondingCurve.toBase58(), associatedBondingCurve: associatedBundingCurve.toBase58() };
    }

    async function getBuyInstructions(
        connection: Connection,
        wallet: Wallet,
        mint: PublicKey,
        bondingCurve: PublicKey,
        associatedBondingCurve: PublicKey,
        associatedTokenPDA: PublicKey,
        amount: number,
        slippage: number,
        calculateSlippage = true,
    ) {
        console.log('amount', amount);
        const provider = new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions());
        const program = new Program(pumpProgramIdl, PUMP_FUN_PROGRAM_ID, provider);
        let { tokenReceivedWithLiquidity, maxSolAmount } = calculateSlippage
            ? await calculateBuyAmounts(program, bondingCurve, amount, slippage)
            : calculateBuyAmountsForAnEmptyCurve(amount, slippage);

        if (slippage === 100) maxSolAmount = new BN(100 * LAMPORTS_PER_SOL);

        const instruction = await program.methods
            .buy(tokenReceivedWithLiquidity, maxSolAmount)
            .accounts({
                global: new PublicKey('4wTV1YmiEkRvAtNtsSGPtUrqRYQMe5SKy2uB4Jjaxnjf'),
                feeRecipient: new PublicKey('CebN5WGQ4jvEPvsVU4EoHEpgzq1VV7AbicfhtW4xC9iM'),
                mint: mint,
                bondingCurve: new PublicKey(bondingCurve),
                associatedBondingCurve: new PublicKey(associatedBondingCurve),
                associatedUser: associatedTokenPDA,
                user: wallet.publicKey,
                systemProgram: SystemProgram.programId,
                tokenProgram: TOKEN_PROGRAM_ID,
                rent: RENT_PROGRAM,
                eventAuthority: PublicKey.findProgramAddressSync(
                    [Buffer.from('__event_authority')],
                    new PublicKey(PUMP_FUN_PROGRAM_ID),
                )[0],
                program: new PublicKey(PUMP_FUN_PROGRAM_ID),
            })
            .instruction();

        return instruction;
    }

    async function getDeployMintInstructions(
        connection: Connection,
        wallet: Wallet,
        mint: Keypair,
        tokenName: string,
        tokenSymbol: string,
        tokenMetadataUri: string,
    ) {
        const provider = new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions());
        const program = new Program(pumpProgramIdl, PUMP_FUN_PROGRAM_ID, provider);
        const bondingCurve = PublicKey.findProgramAddressSync(
            [Buffer.from('bonding-curve'), mint.publicKey.toBuffer()],
            new PublicKey(PUMP_FUN_PROGRAM_ID),
        )[0];

        const instruction = await program.methods
            .create(tokenName, tokenSymbol, tokenMetadataUri)
            .accounts({
                mint: mint.publicKey,
                mintAuthority: PublicKey.findProgramAddressSync(
                    [Buffer.from('mint-authority')],
                    new PublicKey(PUMP_FUN_PROGRAM_ID),
                )[0],
                bondingCurve: bondingCurve,
                associatedBondingCurve: PublicKey.findProgramAddressSync(
                    [bondingCurve.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.publicKey.toBuffer()],
                    new PublicKey(ASSOCIATED_TOKEN_PROGRAM_ID),
                )[0],
                global: PublicKey.findProgramAddressSync([Buffer.from('global')], new PublicKey(PUMP_FUN_PROGRAM_ID))[0],
                mplTokenMetadata: new PublicKey(MPL_TOKEN_METADATA),
                metadata: PublicKey.findProgramAddressSync(
                    [Buffer.from('metadata'), new PublicKey(MPL_TOKEN_METADATA).toBuffer(), mint.publicKey.toBuffer()],
                    new PublicKey(MPL_TOKEN_METADATA),
                )[0],
                user: wallet.publicKey,
                systemProgram: SystemProgram.programId,
                tokenProgram: TOKEN_PROGRAM_ID,
                associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                rent: new PublicKey(RENT_PROGRAM),
                eventAuthority: new PublicKey('Ce6TQqeHC9p8KetsN6JsjHK7UTZk7nasjjnr7XxXp9F1'),
                program: new PublicKey(PUMP_FUN_PROGRAM_ID),
            })
            .instruction();

        //console.log(instruction.keys.map((key) => key.pubkey.toBase58()));
        return instruction;
    }
    function calculateBuyAmountsForAnEmptyCurve(solAmount: number, slippage: number) {
        //console.log('Not calculating slippage');
        let tokensSold;
        const virtualSolReserves = new BN(30000000000);
        const virtualTokenReserves = new BN(1000000000000000);
        const realTokenReserves = new BN(800000000000000);
        const totalLiquidity = virtualSolReserves.mul(virtualTokenReserves);
        const newSolReserve = virtualSolReserves.add(new BN(solAmount * LAMPORTS_PER_SOL));
        const pricePerToken = totalLiquidity.div(newSolReserve).add(new BN(1));

        tokensSold = virtualTokenReserves.sub(pricePerToken);
        tokensSold = BN.min(tokensSold, realTokenReserves);

        const maxSolAmount = new BN(solAmount * LAMPORTS_PER_SOL).mul(new BN(100 + slippage)).div(new BN(100));

        return { tokenReceivedWithLiquidity: tokensSold, maxSolAmount: maxSolAmount };
    }

    /**
     * Make sell instruction
     * @param wallet
     * @param mint
     * @param bondingCurve
     * @param associatedBondingCurve
     * @param associatedTokenPDA
     * @param amount
     * @param slippage
     * @returns Instruction
     */
    async function getSellInstruction(
        connection: Connection,
        wallet: Wallet,
        mint: PublicKey,
        bondingCurve: PublicKey,
        associatedBondingCurve: PublicKey,
        associatedTokenPDA: PublicKey,
        amount: number,
        slippage: number,
    ) {
        //console.log('amount', amount);
        const provider = new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions());
        const program = new Program(pumpProgramIdl, PUMP_FUN_PROGRAM_ID, provider);
        const liquidityPool = await program.account.bondingCurve.fetch(bondingCurve, 'processed');
        console.log('liquidityPool', liquidityPool);
        const solAmount = new BN(amount).mul(liquidityPool.virtualSolReserves).div(new BN(liquidityPool.virtualTokenReserves));
        console.log('solAmount', solAmount.toString());
        const minSolAmount = solAmount.mul(new BN(100 - slippage)).div(new BN(100));

        console.log('sol amount', solAmount.toString());
        console.log('minSolAmount', minSolAmount.toString());
        const instruction = await program.methods
            .sell(new BN(amount), minSolAmount)
            .accounts({
                global: new PublicKey('4wTV1YmiEkRvAtNtsSGPtUrqRYQMe5SKy2uB4Jjaxnjf'),
                feeRecipient: new PublicKey('CebN5WGQ4jvEPvsVU4EoHEpgzq1VV7AbicfhtW4xC9iM'),
                mint: mint,
                bondingCurve: new PublicKey(bondingCurve),
                associatedBondingCurve: new PublicKey(associatedBondingCurve),
                associatedUser: associatedTokenPDA,
                user: wallet.publicKey,
                systemProgram: SystemProgram.programId,
                tokenProgram: TOKEN_PROGRAM_ID,
                rent: new PublicKey('SysvarRent111111111111111111111111111111111'),
                eventAuthority: PublicKey.findProgramAddressSync(
                    [Buffer.from('__event_authority')],
                    new PublicKey(PUMP_FUN_PROGRAM_ID),
                )[0],
                program: new PublicKey(PUMP_FUN_PROGRAM_ID),
            })
            .instruction();

        return instruction;
    }

    /**
     * Calculate buy amount
     * @param program
     * @param bondingCurve
     * @param amount
     * @param slippage
     * @returns Object
     */
    async function calculateBuyAmounts(program: Program, bondingCurve: PublicKey, amount: number, slippage: number) {
        const liquidityPool = await program.account.bondingCurve.fetch(bondingCurve, 'processed');
        // console.log(
        //     `Pool`,
        //     liquidityPool.virtualSolReserves.toString(),
        //     liquidityPool.virtualTokenReserves.toString(),
        //     liquidityPool.realTokenReserves.toString(),
        // );
        const tokenReceivedWithLiquidity = getExchangeRate(Math.floor(1e9 * amount), liquidityPool);
        const solAmount = new BN(Math.floor(1e9 * amount));
        const maxSolAmount = solAmount.mul(new BN(100 + slippage)).div(new BN(100));

        return {
            tokenReceivedWithLiquidity,
            maxSolAmount,
        };
    }

    /**
     * Get the exchange rate
     * @param purchaseAmount Number
     * @param liquidityPool Object
     * @returns Big Number
     */
    function getExchangeRate(purchaseAmount: number, liquidityPool: any) {
        let tokensSold;
        const totalLiquidity = liquidityPool.virtualSolReserves.mul(liquidityPool.virtualTokenReserves);
        const newSolReserve = liquidityPool.virtualSolReserves.add(new BN(purchaseAmount));
        const pricePerToken = totalLiquidity.div(newSolReserve).add(new BN(1));

        tokensSold = liquidityPool.virtualTokenReserves.sub(pricePerToken);
        tokensSold = BN.min(tokensSold, liquidityPool.realTokenReserves);
        return tokensSold;
    }

    return {
        getBuyInstructions,
        getDetailsFromTokenMint,
        getSellInstruction,
        getDeployMintInstructions,
        getExchangeRate,
    };
};

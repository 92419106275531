import axios, { AxiosInstance } from 'axios';
import { Keypair } from '@solana/web3.js';

enum Endpoints {
    Vanity = 'pump/vanity',
}

type GetVanityResponse = Array<number>;

class PVMB {
    private readonly axios: AxiosInstance;

    constructor(baseURL: string, apiKey: string) {
        this.axios = axios.create({
            baseURL,
            headers: {
                'x-api-key': apiKey,
            },
        });
        this.axios.interceptors.response.use((res) => res.data);
    }

    public async getVanity() {
        const res = await this.axios.get<GetVanityResponse, GetVanityResponse>(Endpoints.Vanity);
        return Keypair.fromSecretKey(Uint8Array.from(res));
    }
}

if (!process.env.REACT_APP_PVMB_API_URL) throw new Error('REACT_APP_MINT_TS_BACKEND not provided');
if (!process.env.REACT_APP_PVMB_API_KEY) throw new Error('REACT_APP_MINT_TS_BACKEND_APIKEY not provided');

// eslint-disable-next-line import/no-anonymous-default-export
export default new PVMB(process.env.REACT_APP_PVMB_API_URL, process.env.REACT_APP_PVMB_API_KEY);

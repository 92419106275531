import {
    Connection,
    Keypair,
    LAMPORTS_PER_SOL,
    PublicKey,
    SystemProgram,
    TransactionInstruction,
    TransactionMessage,
    VersionedTransaction,
} from '@solana/web3.js';

import FundsManagerCSS from '../styles/FundsManager.module.css';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import bs58 from 'bs58';
import { useSolana } from '../hooks/useSolana';
import { useMetaplex } from '../hooks/useMetaplex';
import { removeKeypairDuplicates } from '../utils/remove-keypair-duplicates';
import { useClipboard } from '../hooks/useClipboars';
import { useSplToken } from '../hooks/useSplToken';
import { createAssociatedTokenAccountInstruction, createTransferInstruction, getAssociatedTokenAddress } from '@solana/spl-token';
import { useJito } from '../hooks/useJito';
import { countRoles, sliceWalletAddress } from '../utils/role-formatting';

const jitoTip = 0.0002;

export function FundsManager({
    connection,
    deployers,
    buyers,
    mix,
    jitoTips,
    solBuys,
    tokenMint,
    tokenLoaded,
    tokenName,
    tokenSymbol,
    logMessage,
    allWalletRoles,
}: {
    connection: Connection;
    deployers: Keypair[];
    buyers: Keypair[];
    mix: Keypair[];
    jitoTips: number[];
    solBuys: number[];
    tokenMint: string;
    tokenLoaded: boolean;
    tokenName: string;
    tokenSymbol: string;
    logMessage: (message: string, type: 'success' | 'error' | 'info', url?: 'jito' | 'solscan', transaction?: string) => void;
    allWalletRoles: string[];
}) {
    const { getMultipleSolBalances, multipleSolanaTrasfersWithJito } = useSolana();
    const { getNameAndSymbol } = useMetaplex();
    const { sendTokensFromAllWalletsToReceiver, checkAssociatedTokenAcount } = useSplToken();
    const { sendBundle } = useJito();

    const { isCopied, copyToClipboard } = useClipboard();
    const [privateKeyToDisperse, setPrivateKeyToDisperse] = useState<string>('');
    const [mixWalletCheckbox, setMixWalletCheckbox] = useState<boolean>(true);

    const [disperseWallet, setDisperseWallet] = useState<Keypair | null>(null);

    const [disperseWalletSol, setDisperseWalletSol] = useState<number>(0);

    const maxAdditionalSolForWallets = 0.022;
    const minAdditionalSolForWallets = 0.02;
    const fundingNeededSol =
        jitoTips.reduce((a, b) => a + b, 0) +
        solBuys.reduce((a, b) => a + b, 0) +
        buyers.length * jitoTip +
        buyers.length * maxAdditionalSolForWallets;

    const [collectSolDeployer, setCollectSolDeployer] = useState<boolean>(true);
    const [collectSolBuy, setCollectSolBuy] = useState<boolean>(true);
    const [collectSolMix, setCollectSolMix] = useState<boolean>(true);
    const availableDestinationWalletsCollectSol = buyers.concat(mix).map((wallet) => wallet.publicKey.toString());
    const [destinationWalletCollectSol, setDestinationWalletCollect] = useState<number>(0);
    const [customWalletCheckboxCollectSol, setCustomWalletCheckboxCollectSol] = useState<boolean>(true);
    const [customWalletInputCollectSol, setCustomWalletInputCollectSol] = useState<string>('');

    const [fundsManagerTokenMint, setFundsManagerTokenMint] = useState<string>(tokenMint);
    const [fundsManagerTokenLoaded, setFundsManagerTokenLoaded] = useState<boolean>(tokenLoaded);
    const [fundsManagerTokenName, setFundsManagerTokenName] = useState<string>(tokenName);
    const [fundsManagerTokenSymbol, setFundsManagerTokenSymbol] = useState<string>(tokenSymbol);

    const [collectTokenDeployer, setCollectTokenDeployer] = useState<boolean>(true);
    const [collectTokenBuy, setCollectTokenBuy] = useState<boolean>(true);
    const availableDestinationWalletsToken = buyers.concat(mix).map((wallet) => wallet.publicKey.toString());
    const [destinationWalletToken, setDestinationWalletToken] = useState<number>(0);
    const [tokenCustomWalletCheckbox, setTokenCustomWalletCheckbox] = useState<boolean>(false);
    const [tokenCustomWalletInput, setTokenCustomWalletInput] = useState<string>('');

    const availableSourceWalletsSendToken = buyers.concat(mix).map((wallet) => wallet.publicKey.toString());
    const [sourceWalletSendToken, setSourceWalletSendToken] = useState<string>(availableSourceWalletsSendToken[0]);
    const availableDestinationWalletsSendToken = buyers.concat(mix).map((wallet) => wallet.publicKey.toString());
    const [destinationWalletSendToken, setDestinationWalletSendToken] = useState<string>(availableDestinationWalletsSendToken[1]);
    const [tokenAmountToSend, setTokenAmountToSend] = useState<number>(0);
    const [sendTokenCustomWalletCheckbox, setSendTokenCustomWalletCheckbox] = useState<boolean>(false);
    const [sendTokenCustomWalletInput, setSendTokenCustomWalletInput] = useState<string>('');

    const availableSourceWalletsSendSol = buyers.concat(mix).map((wallet) => wallet.publicKey.toString());
    const [sourceWalletSendSol, setSourceWalletSendSol] = useState<string>(availableSourceWalletsSendSol[0]);
    const availableDestinationWalletsSendSol = buyers.concat(mix).map((wallet) => wallet.publicKey.toString());
    const [destinationWalletSendSol, setDestinationWalletSendSol] = useState<string>(availableDestinationWalletsSendSol[1]);
    const [sendSolCustomWalletCheckbox, setSendSolCustomWalletCheckbox] = useState<boolean>(false);
    const [sendSolCustomWalletInput, setSendSolCustomWalletInput] = useState<string>('');
    const [solAmountToSend, setSolAmountToSend] = useState<number>(0);

    const countedRoles = countRoles(allWalletRoles);

    const privateKeyToDisperseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrivateKeyToDisperse(event.target.value);
    };

    const mixWalletCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMixWalletCheckbox(event.target.checked);
    };

    const collectSolDeployerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCollectSolDeployer(event.target.checked);
    };

    const collectSolBuyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCollectSolBuy(event.target.checked);
    };

    const collectSolMixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCollectSolMix(event.target.checked);
    };

    const destinationWalletCollectSolChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDestinationWalletCollect(availableDestinationWalletsCollectSol.indexOf(event.target.value));
    };

    const customWalletCheckboxCollectSolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomWalletCheckboxCollectSol(event.target.checked);
    };

    const customWalletInputCollectSolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomWalletInputCollectSol(event.target.value);
    };

    const collectTokenDeployerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCollectTokenDeployer(event.target.checked);
    };

    const collectTokenBuyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCollectTokenBuy(event.target.checked);
    };

    const destinationWalletTokenChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log(`destinationWalletTokenChange: ${event.target.value}`);
        setDestinationWalletToken(availableDestinationWalletsToken.indexOf(event.target.value));
    };

    const tokenCustomWalletCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTokenCustomWalletCheckbox(event.target.checked);
    };

    const tokenCustomWalletInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTokenCustomWalletInput(event.target.value);
    };

    const sourceWalletSendTokenChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSourceWalletSendToken(event.target.value);
    };

    const tokenAmountToSendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTokenAmountToSend(Number(event.target.value));
    };

    const destinationWalletSendTokenChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDestinationWalletSendToken(event.target.value);
    };

    const sendTokenCustomWalletCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSendTokenCustomWalletCheckbox(event.target.checked);
    };

    const sendTokenCustomWalletInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSendTokenCustomWalletInput(event.target.value);
    };

    const sourceWalletSendSolChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSourceWalletSendSol(event.target.value);
    };

    const destinationWalletSendSolChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDestinationWalletSendSol(event.target.value);
    };

    const sendSolCustomWalletCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSendSolCustomWalletCheckbox(event.target.checked);
    };

    const sendSolCustomWalletInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSendSolCustomWalletInput(event.target.value);
    };

    const solAmountToSendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSolAmountToSend(Number(event.target.value));
    };

    const fundsManagerTokenMintChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFundsManagerTokenMint(event.target.value);
    };

    async function loadToken() {
        if (fundsManagerTokenMint !== '') {
            try {
                const data = await getNameAndSymbol(connection, fundsManagerTokenMint);
                if (data) {
                    const [name, symbol] = data;
                    setFundsManagerTokenName(name);
                    setFundsManagerTokenSymbol(symbol);
                    setFundsManagerTokenLoaded(true);
                }
            } catch (error) {
                logMessage('Invalid mint public key!', 'error');
                setFundsManagerTokenName('');
                setFundsManagerTokenSymbol('');
            }
        }
    }

    async function parseDisperseWallet(dispersePrivateKet: string) {
        try {
            const disperseWallet = Keypair.fromSecretKey(bs58.decode(dispersePrivateKet));
            setDisperseWallet(disperseWallet);
            const balance = await connection.getBalance(disperseWallet.publicKey, 'processed');
            setDisperseWalletSol(balance / LAMPORTS_PER_SOL);
        } catch (e) {
            toast.error(`Error: ${e}`);
        }
    }

    async function solDisperse(
        connection: Connection,
        walletFrom: Keypair,
        buyWallets: Keypair[],
        jitoTips: number[],
        buyValues: number[],
        mixWallets: Keypair[],
        useMixWallets: boolean,
    ) {
        try {
            if (buyWallets.length === 0) {
                return toast.error('No wallets to disperse to.');
            }
            if (useMixWallets && mixWallets.length < buyWallets.length - 1) {
                return toast.error(`Not enough mix wallets. Need at least ${buyWallets.length - 1}.`);
            }
            let disperseWalletBalance = await connection.getBalance(walletFrom.publicKey, 'processed');
            setDisperseWalletSol(disperseWalletBalance / LAMPORTS_PER_SOL);
            if (disperseWalletBalance < fundingNeededSol * LAMPORTS_PER_SOL) {
                console.log(disperseWalletBalance, fundingNeededSol * LAMPORTS_PER_SOL);
                return toast.error('Insufficient funds in disperse wallet.');
            }
            toast.info('Dispersing sol ...');
            const senders: Keypair[] = [];
            const receivers: PublicKey[] = [];
            const sendAmounts: number[] = [];

            const walletsToSend = [...buyWallets];
            walletsToSend.shift();
            const rawSenders = [walletFrom, ...walletsToSend];

            const finalBalances: number[] = [];
            for (let i = 0; i < buyWallets.length; i++) {
                finalBalances.push(
                    Math.floor(
                        (jitoTips[i] +
                            buyValues[i] +
                            jitoTip +
                            (Math.random() * (maxAdditionalSolForWallets - minAdditionalSolForWallets) +
                                minAdditionalSolForWallets)) *
                            LAMPORTS_PER_SOL,
                    ),
                );
            }
            let currentSpend = finalBalances.reduce((a, b) => a + b, 0);
            console.log(
                finalBalances.map((balance) => balance / LAMPORTS_PER_SOL),
                currentSpend / LAMPORTS_PER_SOL,
            );
            console.log(rawSenders.map((sender) => sender.publicKey.toString()));
            for (let i = 0; i < rawSenders.length - 1; i++) {
                senders.push(rawSenders[i]);
                sendAmounts.push(currentSpend);
                if (useMixWallets && i < mixWallets.length) {
                    receivers.push(mixWallets[i].publicKey);
                    senders.push(mixWallets[i]);
                    sendAmounts.push(currentSpend);
                }
                receivers.push(rawSenders[i + 1].publicKey);

                currentSpend -= finalBalances[i];
            }
            console.log(
                senders.map((sender) => sender.publicKey.toString()),
                receivers.map((receiver) => receiver.toBase58()),
                sendAmounts.map((amount) => amount / LAMPORTS_PER_SOL),
            );
            await multipleSolanaTrasfersWithJito(
                connection,
                walletFrom,
                senders,
                receivers,
                sendAmounts,
                jitoTip * LAMPORTS_PER_SOL,
                logMessage,
            );

            toast.success(`All bundles confirmed.`);
            logMessage(`All bundles confirmed.`, 'success');

            disperseWalletBalance = await connection.getBalance(walletFrom.publicKey, 'processed');
            setDisperseWalletSol(disperseWalletBalance / LAMPORTS_PER_SOL);
        } catch (e) {
            toast.error(`Error: ${e}`);
        }
    }

    async function solCollect(connection: Connection, receiverKey: string, walletsFrom: Keypair[]) {
        try {
            const receiver = Keypair.fromSecretKey(bs58.decode(receiverKey));
            toast.info('Collecting sol ...');
            walletsFrom = removeKeypairDuplicates(walletsFrom);
            const balances = await getMultipleSolBalances(
                connection,
                walletsFrom.map((wallet) => wallet.publicKey),
            );
            console.log(balances);
            await multipleSolanaTrasfersWithJito(
                connection,
                receiver,
                walletsFrom,
                new Array(walletsFrom.length).fill(receiver.publicKey),
                balances,
                jitoTip * LAMPORTS_PER_SOL,
                logMessage,
            );
            toast.success(`All sol collect bundles confirmed.`);
            logMessage(`All bundles confirmed.`, 'success');
        } catch (e) {
            toast.error(`Error: ${e}`);
        }
    }

    async function collectToken(
        connection: Connection,
        fundsManagerTokenMint: PublicKey,
        receiver: PublicKey,
        walletsFrom: Keypair[],
        jitoTip: number,
    ) {
        toast.info('Sending all tokens to a wallet...');
        if (receiver.toBase58() === PublicKey.default.toBase58()) {
            return toast.error('Wallet not found!');
        }
        await sendTokensFromAllWalletsToReceiver(
            connection,
            fundsManagerTokenMint,
            receiver,
            walletsFrom,
            jitoTip * LAMPORTS_PER_SOL,
            logMessage,
        );

        toast.success(`All tokens sent to ${receiver.toBase58()}`);
        logMessage(`All tokens sent to ${receiver.toBase58()}`, 'success');
    }

    async function sendToken(
        connection: Connection,
        tokenMint: PublicKey,
        sourceWallet: Keypair,
        destinationWallet: PublicKey,
        amount: number,
    ) {
        try {
            if (amount === 0) return toast.error('Amount must be greater than 0');
            //check if token accounts exist
            const instructions: TransactionInstruction[] = [];
            const accountFrom = await getAssociatedTokenAddress(tokenMint, sourceWallet.publicKey);
            const accountTo = await getAssociatedTokenAddress(tokenMint, destinationWallet);

            const destinationTokenAccount = await checkAssociatedTokenAcount(connection, destinationWallet, tokenMint);

            if (destinationTokenAccount.length === 0) {
                console.log('Creating associated token account');
                instructions.push(
                    createAssociatedTokenAccountInstruction(sourceWallet.publicKey, accountTo, destinationWallet, tokenMint),
                );
            }

            const transferInstruction = createTransferInstruction(accountFrom, accountTo, sourceWallet.publicKey, amount);
            instructions.push(transferInstruction);

            const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();
            const message = new TransactionMessage({
                payerKey: sourceWallet.publicKey,
                recentBlockhash: blockhash,
                instructions: instructions,
            }).compileToV0Message();

            const tx = new VersionedTransaction(message);
            tx.sign([sourceWallet]);
            const bundleId = await sendBundle(connection, [tx], sourceWallet, jitoTip * LAMPORTS_PER_SOL);
            // const txs1 = await connection.sendRawTransaction(tx.serialize(), { skipPreflight: true });
            // console.log(txs1);
            toast.info(`Transaction sent. Bundle ID: ${bundleId}`);

            await connection.confirmTransaction(
                { blockhash: blockhash, lastValidBlockHeight: lastValidBlockHeight, signature: bs58.encode(tx.signatures[0]) },
                'confirmed',
            );
            toast.success('Transaction confirmed.');
        } catch (e) {
            toast.error(`Error: ${e}`);
            logMessage(`Error: ${e}`, 'error');
            console.log(e);
        }
    }

    async function singleSolTransfer(
        connection: Connection,
        sourceWallet: Keypair,
        destinationWallet: PublicKey,
        amount: number,
    ) {
        try {
            if (amount === 0) return toast.error('Amount must be greater than 0');

            const transferInstruction = SystemProgram.transfer({
                fromPubkey: sourceWallet.publicKey,
                toPubkey: destinationWallet,
                lamports: amount,
            });
            const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();
            const message = new TransactionMessage({
                payerKey: sourceWallet.publicKey,
                recentBlockhash: blockhash,
                instructions: [transferInstruction],
            }).compileToV0Message();
            const tx = new VersionedTransaction(message);
            tx.sign([sourceWallet]);
            const bundleId = await sendBundle(connection, [tx], sourceWallet, jitoTip * LAMPORTS_PER_SOL);
            toast.info(`Transaction sent. Bundle ID: ${bundleId}`);

            await connection.confirmTransaction(
                { blockhash: blockhash, lastValidBlockHeight: lastValidBlockHeight, signature: bs58.encode(tx.signatures[0]) },
                'confirmed',
            );
            toast.success('Transaction confirmed.');
        } catch (e) {
            toast.error(`Error: ${e}`);
            logMessage(`Error: ${e}`, 'error');
            console.log(e);
        }
    }

    return (
        <section className={FundsManagerCSS.container}>
            <h1 className={FundsManagerCSS.blockName}>SOL - Solana Management</h1>
            <div className={`${FundsManagerCSS.blockItem} blockRoundBorder`}>
                <h2 className={FundsManagerCSS.header}>Disperse SOL</h2>
                <div className={FundsManagerCSS.privateKeyInputWrap}>
                    <label>
                        <b>Private key with SOL to disperse</b>
                        <input type='text' value={privateKeyToDisperse} onChange={privateKeyToDisperseChange} />
                    </label>
                    <button className='buttonAccentGreen' onClick={() => parseDisperseWallet(privateKeyToDisperse)}>
                        SET
                    </button>
                </div>
                <label className={FundsManagerCSS.mixWalletCheckbox}>
                    <input type='checkbox' checked={mixWalletCheckbox} onChange={mixWalletCheckboxChange} />
                    Mix Wallet Procedure
                </label>
                <div className={FundsManagerCSS.mixWalletInfo}>
                    <p>
                        {buyers.length - deployers.length} BUY WALLETS / {mix.length} MIX WALLETS DETECTED
                    </p>
                </div>
                <div className={FundsManagerCSS.disperseInfoContainer}>
                    <div className={FundsManagerCSS.disperseInfoWrap}>
                        <div>
                            <p>Disperse Wallet SOL:</p>
                            <p className={FundsManagerCSS.disperseInfoValues}>
                                <span>{disperseWalletSol.toFixed(2)}</span> SOL
                            </p>
                        </div>
                        <div>
                            <p>Funding Needed SOL:</p>
                            <p className={FundsManagerCSS.disperseInfoValues}>
                                <span>{fundingNeededSol.toFixed(2)}</span> SOL
                            </p>
                        </div>
                    </div>
                    <button
                        className='buttonAccentGreen'
                        onClick={() =>
                            solDisperse(connection, disperseWallet!, buyers, jitoTips, solBuys, mix, mixWalletCheckbox)
                        }
                    >
                        DISPERSE
                    </button>
                </div>
            </div>
            <div className={`${FundsManagerCSS.blockItem} blockRoundBorder`}>
                <h2 className={FundsManagerCSS.headerWithInfo}>Collect All SOL</h2>
                <p className={FundsManagerCSS.blockInfo}>Collects ALL SOL from Selected Wallet Roles on Loaded CSV</p>
                <div className={FundsManagerCSS.rolesCheckboxes}>
                    <label>
                        <input type='checkbox' checked={collectSolDeployer} onChange={collectSolDeployerChange} />
                        <span>Deployer</span>
                    </label>
                    <label>
                        <input type='checkbox' checked={collectSolBuy} onChange={collectSolBuyChange} />
                        <span>Buy Wallets</span>
                    </label>
                    <label>
                        <input type='checkbox' checked={collectSolMix} onChange={collectSolMixChange} />
                        <span>Mix Wallets</span>
                    </label>
                </div>
                <div className={FundsManagerCSS.collectWrap}>
                    <label>
                        <b>Destination Wallet</b>
                        <select
                            value={availableDestinationWalletsSendSol[destinationWalletCollectSol]}
                            onChange={destinationWalletCollectSolChange}
                            disabled={customWalletCheckboxCollectSol}
                        >
                            {availableDestinationWalletsCollectSol.map((destinationWalletCollectSol, index) => (
                                <option value={destinationWalletCollectSol} key={index}>
                                    {`${sliceWalletAddress(destinationWalletCollectSol, 5)} (${countedRoles[index]})`}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label>
                        <input
                            type='checkbox'
                            checked={customWalletCheckboxCollectSol}
                            onChange={customWalletCheckboxCollectSolChange}
                        />
                        Custom
                    </label>
                    <button
                        className='buttonAccentGreen'
                        onClick={() => {
                            const walletsToCollect: Keypair[] = [];
                            if (collectSolDeployer) {
                                walletsToCollect.push(...deployers);
                            }
                            if (collectSolBuy) {
                                walletsToCollect.push(...buyers);
                            }
                            if (collectSolMix) {
                                walletsToCollect.push(...mix);
                            }

                            let destination: string;
                            if (customWalletCheckboxCollectSol) {
                                destination = customWalletInputCollectSol;
                            } else {
                                const allWallets = [...buyers, ...mix];
                                console.log(allWallets.length);
                                console.log(destinationWalletCollectSol);
                                console.log(allWallets[destinationWalletCollectSol].publicKey.toString());
                                destination = bs58.encode(allWallets[destinationWalletCollectSol].secretKey);
                            }
                            solCollect(connection, destination, walletsToCollect);
                        }}
                    >
                        COLLECT
                    </button>
                </div>
                {customWalletCheckboxCollectSol && (
                    <div className={FundsManagerCSS.customWalletWrap}>
                        <label>
                            <b>Custom Wallet PrivateKey</b>
                            <input
                                type='text'
                                value={customWalletInputCollectSol}
                                onChange={customWalletInputCollectSolChange}
                                disabled={!customWalletCheckboxCollectSol}
                            />
                        </label>
                    </div>
                )}
            </div>
            <h1 className={FundsManagerCSS.blockName}>Token Management</h1>
            {fundsManagerTokenLoaded ? (
                <div className={FundsManagerCSS.tokenInfoBlock}>
                    <h2>Token</h2>
                    <p>
                        <span>{fundsManagerTokenName}</span> | <span>{fundsManagerTokenSymbol}</span>
                    </p>
                    <p className={FundsManagerCSS.tokenMint}>{fundsManagerTokenMint}</p>
                    <button
                        className={isCopied ? 'copiedIcon' : 'copyIcon'}
                        onClick={() => copyToClipboard(fundsManagerTokenMint)}
                    ></button>
                    <button
                        onClick={() => {
                            setFundsManagerTokenMint('');
                            setFundsManagerTokenLoaded(false);
                        }}
                        className={FundsManagerCSS.editMint}
                    ></button>
                </div>
            ) : (
                <div className={FundsManagerCSS.tokenInfoBlock}>
                    <h2>Set Token</h2>
                    <input type='text' value={fundsManagerTokenMint} onChange={fundsManagerTokenMintChange} />
                    <button className='buttonAccentGreen' onClick={loadToken}>
                        SET
                    </button>
                </div>
            )}
            <div className={`${FundsManagerCSS.blockItem} blockRoundBorder`}>
                <h2 className={FundsManagerCSS.headerWithInfo}>Collect ALL Tokens</h2>
                <p className={FundsManagerCSS.blockInfo}>Collects Tokens from Selected Wallet Roles on Loaded CSV</p>
                <div className={FundsManagerCSS.rolesCheckboxes}>
                    <label>
                        <input type='checkbox' checked={collectTokenDeployer} onChange={collectTokenDeployerChange} />
                        <span>Deployer</span>
                    </label>
                    <label>
                        <input type='checkbox' checked={collectTokenBuy} onChange={collectTokenBuyChange} />
                        <span>Buy Wallets</span>
                    </label>
                </div>
                <div className={FundsManagerCSS.collectWrap}>
                    <label>
                        <b>Destination Wallet</b>
                        <select
                            value={availableDestinationWalletsToken[destinationWalletToken]}
                            onChange={destinationWalletTokenChange}
                            disabled={tokenCustomWalletCheckbox}
                        >
                            {availableDestinationWalletsToken.map((destinationWalletToken, index) => (
                                <option value={destinationWalletToken} key={index}>
                                    {`${sliceWalletAddress(destinationWalletToken, 5)} (${countedRoles[index]})`}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label>
                        <input type='checkbox' checked={tokenCustomWalletCheckbox} onChange={tokenCustomWalletCheckboxChange} />
                        Custom
                    </label>
                    <button
                        disabled={!fundsManagerTokenLoaded}
                        className='buttonAccentGreen'
                        onClick={() => {
                            const walletsToCollect: Keypair[] = [];
                            if (collectTokenDeployer) {
                                walletsToCollect.push(...deployers);
                            }
                            if (collectTokenBuy) {
                                walletsToCollect.push(...buyers);
                            }

                            let destinationWallet;
                            if (tokenCustomWalletCheckbox) {
                                destinationWallet = tokenCustomWalletInput;
                            } else {
                                destinationWallet = availableDestinationWalletsToken[destinationWalletToken];
                            }
                            try {
                                collectToken(
                                    connection,
                                    new PublicKey(tokenMint),
                                    new PublicKey(destinationWallet),
                                    walletsToCollect,
                                    jitoTip,
                                );
                            } catch (e) {
                                toast.error(`Error: ${e}`);
                                console.log(e);
                            }
                        }}
                    >
                        COLLECT
                    </button>
                </div>
                {tokenCustomWalletCheckbox && (
                    <div className={FundsManagerCSS.customWalletWrap}>
                        <label>
                            <b>Custom Wallet</b>
                            <input
                                type='text'
                                value={tokenCustomWalletInput}
                                onChange={tokenCustomWalletInputChange}
                                disabled={!tokenCustomWalletCheckbox}
                            />
                        </label>
                    </div>
                )}
            </div>
            <div className={`${FundsManagerCSS.blockItem} blockRoundBorder`}>
                <h2 className={FundsManagerCSS.header}>Send Tokens - Wallet to Wallet</h2>
                <label className={FundsManagerCSS.sourceWallet}>
                    <b>Source Wallet</b>
                    <select value={sourceWalletSendToken} onChange={sourceWalletSendTokenChange}>
                        {availableSourceWalletsSendToken.map((sourceWalletSendToken, index) => (
                            <option value={sourceWalletSendToken} key={index}>
                                {`${sliceWalletAddress(sourceWalletSendToken, 5)} (${countedRoles[index]})`}
                            </option>
                        ))}
                    </select>
                </label>
                <div className={FundsManagerCSS.tokenInputWrap}>
                    <label>
                        <b>Tokens</b>
                        <input type='number' value={tokenAmountToSend} onChange={tokenAmountToSendChange} />
                    </label>
                    <button
                        className='buttonAccentGreen'
                        disabled={!fundsManagerTokenLoaded}
                        onClick={() => {
                            const sourceWallet = buyers.find((wallet) => wallet.publicKey.toString() === sourceWalletSendToken);
                            let destinationWallet;
                            if (sendTokenCustomWalletCheckbox) {
                                destinationWallet = sendTokenCustomWalletInput;
                            } else {
                                destinationWallet = destinationWalletSendToken;
                            }
                            sendToken(
                                connection,
                                new PublicKey(tokenMint),
                                sourceWallet!,
                                new PublicKey(destinationWallet),
                                tokenAmountToSend * 1e6,
                            );
                        }}
                    >
                        SEND
                    </button>
                </div>
                <div className={FundsManagerCSS.collectWrap}>
                    <label>
                        <b>Destination Wallet</b>
                        <select
                            value={destinationWalletSendToken}
                            onChange={destinationWalletSendTokenChange}
                            disabled={sendTokenCustomWalletCheckbox}
                        >
                            {availableDestinationWalletsSendToken.map((destinationWalletSendToken, index) => (
                                <option value={destinationWalletSendToken} key={index}>
                                    {`${sliceWalletAddress(destinationWalletSendToken, 5)} (${countedRoles[index]})`}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label>
                        <input
                            type='checkbox'
                            checked={sendTokenCustomWalletCheckbox}
                            onChange={sendTokenCustomWalletCheckboxChange}
                        />
                        Custom
                    </label>
                </div>
                {sendTokenCustomWalletCheckbox && (
                    <div className={FundsManagerCSS.customWalletWrap}>
                        <label>
                            <b>Custom Wallet</b>
                            <input
                                type='text'
                                value={sendTokenCustomWalletInput}
                                onChange={sendTokenCustomWalletInputChange}
                                disabled={!sendTokenCustomWalletCheckbox}
                            />
                        </label>
                    </div>
                )}
            </div>
            <h1 className={FundsManagerCSS.blockName}>Simple Tools</h1>
            <div className={`${FundsManagerCSS.blockItem} blockRoundBorder`}>
                <h2 className={FundsManagerCSS.header}>Send Solana Individual Wallet</h2>
                <div className={FundsManagerCSS.sendSolOptions}>
                    <label>
                        <span>Source Wallet</span>
                        <select value={sourceWalletSendSol} onChange={sourceWalletSendSolChange}>
                            {availableSourceWalletsSendSol.map((sourceWalletSendSol, index) => (
                                <option value={sourceWalletSendSol} key={index}>
                                    {`${sliceWalletAddress(sourceWalletSendSol, 5)} (${countedRoles[index]})`}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label>
                        <span>Destination Wallet</span>
                        <select
                            value={destinationWalletSendSol}
                            onChange={destinationWalletSendSolChange}
                            disabled={sendSolCustomWalletCheckbox}
                        >
                            {availableDestinationWalletsSendSol.map((destinationWalletSendSol, index) => (
                                <option value={destinationWalletSendSol} key={index}>
                                    {`${sliceWalletAddress(destinationWalletSendSol, 5)} (${countedRoles[index]})`}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label>
                        <input
                            type='checkbox'
                            checked={sendSolCustomWalletCheckbox}
                            onChange={sendSolCustomWalletCheckboxChange}
                        />
                        Custom
                    </label>
                </div>
                {sendSolCustomWalletCheckbox && (
                    <div className={FundsManagerCSS.customWalletWrap}>
                        <label>
                            <b>Custom Wallet</b>
                            <input
                                type='text'
                                value={sendSolCustomWalletInput}
                                onChange={sendSolCustomWalletInputChange}
                                disabled={!sendSolCustomWalletCheckbox}
                            />
                        </label>
                    </div>
                )}
                <div className={FundsManagerCSS.sendSolWrap}>
                    <label>
                        <span>SOL</span>
                        <input type='number' value={solAmountToSend} onChange={solAmountToSendChange} />
                    </label>
                    <button
                        className='buttonAccentGreen'
                        onClick={() => {
                            let destinationWallet;
                            if (sendSolCustomWalletCheckbox) {
                                destinationWallet = sendSolCustomWalletInput;
                            } else {
                                destinationWallet = destinationWalletSendSol;
                            }
                            singleSolTransfer(
                                connection,
                                buyers.find((wallet) => wallet.publicKey.toString() === sourceWalletSendSol)!,
                                new PublicKey(destinationWallet),
                                solAmountToSend * LAMPORTS_PER_SOL,
                            );
                        }}
                    >
                        SEND
                    </button>
                </div>
            </div>
        </section>
    );
}

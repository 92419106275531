import { useRef, ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Connection, Keypair, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { toast } from 'react-toastify';

import parseCsv from '../utils/parse-csv';
import bs58 from 'bs58';

import HeaderCSS from '../styles/Header.module.css';
import pumpFunIcon from '../images/pumpFunIcon.png';
import photonIcon from '../images/photonIcon.png';
import { useSolana } from '../hooks/useSolana';
import { removeKeypairDuplicates } from '../utils/remove-keypair-duplicates';

export function Header({
    tokenMint,
    tokenLoaded,
    tokenName,
    tokenSymbol,
    setDeployers,
    setMix,
    wallets,
    setWallets,
    setAllWalletRoles,
    setWalletPublicKeys,
    setDelays,
    setSlippages,
    setJitoTips,
    setSolBuys,
    setTokenBalances,
    setSolValues,
    setTotalInitBalance,
    connection,
    logMessage,
    setBuyerRoles,
}: {
    tokenMint: string;
    tokenLoaded: boolean;
    tokenName: string;
    tokenSymbol: string;
    setDeployers: Dispatch<SetStateAction<Keypair[]>>;
    setMix: Dispatch<SetStateAction<Keypair[]>>;
    wallets: Keypair[];
    setWallets: Dispatch<SetStateAction<Keypair[]>>;
    setAllWalletRoles: Dispatch<SetStateAction<string[]>>;
    setWalletPublicKeys: Dispatch<SetStateAction<string[]>>;
    setDelays: Dispatch<SetStateAction<number[]>>;
    setSlippages: Dispatch<SetStateAction<number[]>>;
    setJitoTips: Dispatch<SetStateAction<number[]>>;
    setSolBuys: Dispatch<SetStateAction<number[]>>;
    setTokenBalances: Dispatch<SetStateAction<number[]>>;
    setSolValues: Dispatch<SetStateAction<number[]>>;
    setTotalInitBalance: Dispatch<React.SetStateAction<number>>;
    connection: Connection;
    logMessage: (message: string, type: 'success' | 'error' | 'info', url?: 'jito' | 'solscan', transaction?: string) => void;
    setBuyerRoles: Dispatch<SetStateAction<string[]>>;
}) {
    const { getMultipleSolBalances } = useSolana();
    const csvFileInput = useRef<HTMLInputElement>(null);

    const loadCsv = (event: React.MouseEvent) => {
        event.preventDefault();
        csvFileInput.current?.click();
    };

    const csvFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            await getVolumeInfo(file);
            event.target.value = '';
        }
    };

    async function getVolumeInfo(file: File) {
        try {
            const lines = await parseCsv(file);

            const deployers: Keypair[] = [];
            const wallets: Keypair[] = [];
            const mix: Keypair[] = [];
            const roles: string[] = [];
            const buyerRoles: string[] = [];

            const solBuys: number[] = [];
            const delays: number[] = [];
            const slippages: number[] = [];
            const jitoTips: number[] = [];

            for (const line of lines) {
                const [privateKey, role, slippage, jitoTip, buyAmount, buyDelayMs] = line;

                roles.push(role);

                switch (role.toLowerCase()) {
                    case 'deployer':
                        const deployer = Keypair.fromSecretKey(bs58.decode(privateKey));
                        deployers.push(deployer);
                        wallets.push(deployer);
                        buyerRoles.push(role);
                        solBuys.push(Number(buyAmount));
                        delays.push(Number(buyDelayMs));
                        slippages.push(Number(slippage));
                        jitoTips.push(Number(jitoTip));
                        break;
                    case 'buy':
                        const wallet = Keypair.fromSecretKey(bs58.decode(privateKey));
                        wallets.push(wallet);
                        buyerRoles.push(role);
                        solBuys.push(Number(buyAmount));
                        delays.push(Number(buyDelayMs));
                        slippages.push(Number(slippage));
                        jitoTips.push(Number(jitoTip));
                        break;
                    case 'mix':
                        const mixWallet = Keypair.fromSecretKey(bs58.decode(privateKey));
                        mix.push(mixWallet);
                        break;
                    default:
                        logMessage(`Invalid role detected: ${role}`, 'error');
                        return toast.error('Invalid role detected');
                }
            }

            const uniqueWallets = removeKeypairDuplicates(deployers.concat(wallets));
            const balances = await getMultipleSolBalances(
                connection,
                uniqueWallets.map((wallet) => wallet.publicKey),
            );

            setDeployers(deployers);
            setMix(mix);
            setAllWalletRoles(roles);
            setBuyerRoles(buyerRoles);
            setWallets(wallets);
            setWalletPublicKeys(wallets.map((wallet) => wallet.publicKey.toBase58()));
            setDelays(delays);
            setSlippages(slippages);
            setJitoTips(jitoTips);
            setSolBuys(solBuys);
            setTokenBalances(new Array(wallets.length).fill(0));
            setSolValues(new Array(wallets.length).fill(0));
            setTotalInitBalance(balances.reduce((acc, balance) => acc + balance, 0) / LAMPORTS_PER_SOL);
        } catch (error) {
            toast.error('Error parsing CSV');
            logMessage(`Error parsing CSV: ${error}`, 'error');
            return toast.error('Error parsing CSV');
        }
    }

    return (
        <header className={HeaderCSS.header}>
            <div className={HeaderCSS.loadCsvWrap}>
                <button className='buttonAccentBlue' onClick={loadCsv}>
                    Load CSV
                </button>
                <input type='file' accept='.csv' onChange={csvFileChange} ref={csvFileInput} />
                {wallets.length ? <b className={HeaderCSS.csvStatusIcon}>✅</b> : <b className={HeaderCSS.csvStatusIcon}>❌</b>}
            </div>
            <div className={HeaderCSS.tokenInfoWrap}>
                <div>
                    {tokenLoaded ? (
                        <p>
                            <span>{tokenName}</span> | <span>{tokenSymbol}</span>
                        </p>
                    ) : (
                        <p className={HeaderCSS.noToken}>Waiting For Token...</p>
                    )}
                </div>
                <div className={HeaderCSS.iconsWrap}>
                    <a
                        className={tokenLoaded ? '' : `${HeaderCSS.isDisabled}`}
                        href={`https://pump.fun/${tokenMint}`}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img className={HeaderCSS.pumpFunIcon} src={pumpFunIcon} alt='Pump Fun Icon' loading='lazy' />
                    </a>
                    <a
                        className={tokenLoaded ? '' : `${HeaderCSS.isDisabled}`}
                        href={`https://photon-sol.tinyastro.io/en/lp/${tokenMint}`}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img className={HeaderCSS.photonIcon} src={photonIcon} alt='Photon Icon' loading='lazy' />
                    </a>
                </div>
            </div>
        </header>
    );
}

import {
    LIQUIDITY_STATE_LAYOUT_V4,
    Liquidity,
    MAINNET_PROGRAM_ID,
    Token,
    TokenAmount,
    TxVersion,
    findProgramAddress,
} from '@raydium-io/raydium-sdk';
import { Connection, PublicKey } from '@solana/web3.js';
import { useSplToken } from './useSplToken';
import { formatAmmKeysById } from '../utils/format-raydiumAmm-keys-by-id';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';

export const useRaydium = () => {
    const { getWalletTokenAccounts, getTokenDecimals } = useSplToken();

    function getAssociatedAmmId({ programId, marketId }: { programId: PublicKey; marketId: PublicKey }) {
        const { publicKey } = findProgramAddress(
            [programId.toBuffer(), marketId.toBuffer(), Buffer.from('amm_associated_seed', 'utf-8')],
            programId,
        );
        return publicKey;
    }

    async function fetchMarketAccountsByMints(
        connection: Connection,
        base: PublicKey,
        quote: PublicKey,
        commitment: 'processed' | 'confirmed' | 'singleGossip' | 'single' | 'recent' | 'max' = 'processed',
    ) {
        try {
            const accounts = await connection.getProgramAccounts(MAINNET_PROGRAM_ID.AmmV4, {
                commitment,
                filters: [
                    { dataSize: LIQUIDITY_STATE_LAYOUT_V4.span },
                    {
                        memcmp: {
                            offset: LIQUIDITY_STATE_LAYOUT_V4.offsetOf('baseMint'),
                            bytes: base.toBase58(),
                        },
                    },
                    {
                        memcmp: {
                            offset: LIQUIDITY_STATE_LAYOUT_V4.offsetOf('quoteMint'),
                            bytes: quote.toBase58(),
                        },
                    },
                ],
            });
            return accounts.map(({ pubkey, account }) => ({
                id: pubkey.toString(),
                ...LIQUIDITY_STATE_LAYOUT_V4.decode(account.data),
            }));
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async function getSwapInstructions(
        connection: Connection,
        walletFrom: PublicKey,
        tokenInKey: PublicKey,
        tokenOutKey: PublicKey,
        marketId: PublicKey,
        amountIn: number,
        amountOut: number,
    ) {
        const walletTokenAccounts = await getWalletTokenAccounts(connection, walletFrom);
        const poolKeys = await formatAmmKeysById(connection, new PublicKey(marketId));

        console.log(amountIn, amountOut);
        const decimalsIn = await getTokenDecimals(connection, tokenInKey);
        const tokenIn = new Token(TOKEN_PROGRAM_ID, tokenInKey, decimalsIn);
        const tokenInAmount = new TokenAmount(tokenIn, amountIn);
        const decimalsOut = await getTokenDecimals(connection, tokenOutKey);
        const tokenOut = new Token(TOKEN_PROGRAM_ID, tokenOutKey, decimalsOut);
        const tokenOutAmount = new TokenAmount(tokenOut, amountOut);

        const response = (
            await Liquidity.makeSwapInstructionSimple({
                connection: connection,
                poolKeys: poolKeys,
                userKeys: {
                    tokenAccounts: walletTokenAccounts,
                    owner: walletFrom,
                    payer: walletFrom,
                },
                amountIn: tokenInAmount,
                amountOut: tokenOutAmount,
                fixedSide: 'in',
                makeTxVersion: TxVersion.V0,
            })
        ).innerTransactions[0].instructions;

        return response;
    }
    return {
        fetchMarketAccountsByMints,
        getAssociatedAmmId,
        getSwapInstructions,
    };
};

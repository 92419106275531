import {
    ASSOCIATED_TOKEN_PROGRAM_ID,
    AccountLayout,
    TOKEN_PROGRAM_ID,
    createAssociatedTokenAccountInstruction,
    createTransferInstruction,
    getAccount,
    getAssociatedTokenAddress,
    getAssociatedTokenAddressSync,
    getMint,
} from '@solana/spl-token';
import {
    Connection,
    Keypair,
    ParsedAccountData,
    PublicKey,
    Signer,
    TransactionInstruction,
    TransactionMessage,
    VersionedTransaction,
} from '@solana/web3.js';
import bs58 from 'bs58';
import { useJito } from './useJito';
import { removeKeypairDuplicates } from '../utils/remove-keypair-duplicates';
import { SPL_ACCOUNT_LAYOUT, TokenAccount } from '@raydium-io/raydium-sdk';

export const useSplToken = () => {
    const { sendBundle } = useJito();
    /**
     * Create Associated Token Account
     * @param {*} connection : Connection
     * @param {*} owner : Keypair
     */
    async function getCreateAssociatedTokenAccountInstruction(connection: Connection, owner: Keypair, mintPublicKey: PublicKey) {
        // Get the associated token account address for the wallet
        const associatedTokenAddress = await getAssociatedTokenAddress(mintPublicKey, owner.publicKey);
        // Check if the associated token account already exists
        const associatedTokenAccountInfo = await connection.getAccountInfo(associatedTokenAddress, 'processed');
        if (!associatedTokenAccountInfo) {
            // Create the associated token account if it doesn't exist
            console.log('Creating associated token account');
            return [
                createAssociatedTokenAccountInstruction(owner.publicKey, associatedTokenAddress, owner.publicKey, mintPublicKey),
            ];
        }
        return [];
    }
    /**
     * find associated Token Address
     * @param {*} walletAddress : PublicKey
     * @param {*} tokenMintAddress : PublicKey
     * @returns
     */
    function findAssociatedTokenAddress(walletAddress: PublicKey, tokenMintAddress: PublicKey) {
        const [result] = PublicKey.findProgramAddressSync(
            [walletAddress.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), tokenMintAddress.toBuffer()],
            ASSOCIATED_TOKEN_PROGRAM_ID,
        );
        return result;
    }

    /**
     * Check if the associated token account exists
     * @param {*} connection : Connection
     * @param {*} walletPublicKey : PublicKey
     * @param {*} tokenMintAddress : PublicKey
     * @returns Boolean
     */
    async function checkAssociatedTokenAcount(connection: Connection, walletPublicKey: PublicKey, tokenMintAddress: PublicKey) {
        // Get the associated token address for your wallet and token mint
        const associatedTokenAddress = await getAssociatedTokenAddress(tokenMintAddress, walletPublicKey);

        // Get account info
        const accountInfo = await connection.getAccountInfo(associatedTokenAddress, 'processed');

        if (accountInfo) {
            return [associatedTokenAddress];
        } else {
            return [];
        }
    }

    async function getWalletTokenAccounts(connection: Connection, wallet: PublicKey): Promise<TokenAccount[]> {
        const walletTokenAccount = await connection.getTokenAccountsByOwner(
            wallet,
            {
                programId: TOKEN_PROGRAM_ID,
            },
            'processed',
        );
        return walletTokenAccount.value.map((i) => ({
            pubkey: i.pubkey,
            programId: i.account.owner,
            accountInfo: SPL_ACCOUNT_LAYOUT.decode(i.account.data),
        }));
    }

    async function getTokenAccountsBalances(connection: Connection, tokenAccounts: PublicKey[]) {
        const results = await connection.getMultipleAccountsInfo(tokenAccounts, 'processed');
        const balances: string[] = [];

        for (const result of results) {
            if (result === null) {
                balances.push('0');
                continue;
            }

            //need to confirm layout
            const accountInfo = AccountLayout.decode(result.data);
            balances.push(accountInfo.amount.toString());
        }

        return balances;
    }
    async function getWalletsTokenStringBalances(
        connection: Connection,
        wallets: PublicKey[],
        mint: PublicKey,
    ): Promise<string[]> {
        const walletTokenAccounts = await Promise.all(
            wallets.map(async (wallet) => {
                const tokenAccounts = await checkAssociatedTokenAcount(connection, wallet, mint);
                return {
                    wallet: wallet,
                    accounts: tokenAccounts.length > 0 ? tokenAccounts : null,
                };
            }),
        );
        const existingAccounts = walletTokenAccounts
            .filter((data) => data.accounts !== null)
            .flatMap((data) => data.accounts!.map((account) => ({ pubkey: account, wallet: data.wallet })));

        const balances = await getTokenAccountsBalances(
            connection,
            existingAccounts.map((account) => account.pubkey),
        );

        const balanceMap = new Map(existingAccounts.map((acc, idx) => [acc.wallet.toBase58(), balances[idx]]));

        return wallets.map((wallet) => {
            const walletKey = wallet.toBase58();
            return balanceMap.has(walletKey) ? balanceMap.get(walletKey)! : '0';
        });
    }

    async function getTokenAccountBalance(connection: Connection, tokenAccount: PublicKey) {
        const tokenAccountBalance = await connection.getParsedAccountInfo(tokenAccount, {
            commitment: 'processed',
        });

        return (tokenAccountBalance.value!.data as ParsedAccountData).parsed.info.tokenAmount;
    }

    async function getCreateTokenAccountInstructions(
        payer: Signer,
        mint: PublicKey,
        owner: PublicKey,
        allowOwnerOffCurve = false,
        programId = TOKEN_PROGRAM_ID,
        associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID,
    ): Promise<TransactionInstruction> {
        const associatedToken = getAssociatedTokenAddressSync(
            mint,
            owner,
            allowOwnerOffCurve,
            programId,
            associatedTokenProgramId,
        );

        const instructions = createAssociatedTokenAccountInstruction(
            payer.publicKey,
            associatedToken,
            owner,
            mint,
            programId,
            associatedTokenProgramId,
        );

        return instructions;
    }

    async function sendTokensFromAllWalletsToReceiver(
        connection: Connection,
        tokenMint: PublicKey,
        receiver: PublicKey,
        walletsFrom: Keypair[],
        jitoTipLamports: number,
        logMessage: (message: string, type: 'success' | 'error' | 'info', url?: 'jito' | 'solscan', transaction?: string) => void,
        instructionsPerTransaction = 4,
        transactionsPerBundle = 4,
    ) {
        const uniqWallet = removeKeypairDuplicates(walletsFrom);
        const fullIntructions: { instruction: TransactionInstruction; signer: Keypair }[] = [];

        const receiverTokenAccount = await getAssociatedTokenAddress(tokenMint, receiver);

        for (const walletFrom of uniqWallet) {
            try {
                if (receiver.toBase58() === walletFrom.publicKey.toBase58()) {
                    continue;
                }
                const tokenAccount = await getAssociatedTokenAddress(tokenMint, walletFrom.publicKey);
                const balance = await getTokenAccountBalance(connection, tokenAccount);
                if (balance.amount === '0') {
                    continue;
                }
                console.log(`Sender token account: ${tokenAccount.toBase58()}`);
                const instruction = createTransferInstruction(
                    tokenAccount,
                    receiverTokenAccount,
                    walletFrom.publicKey,
                    balance.amount,
                );
                console.log('from', walletFrom.publicKey.toBase58());
                fullIntructions.push({ instruction: instruction, signer: walletFrom });
            } catch {
                console.log(`FAILED TO SEND TOKEN FROM ACCOUNT ${walletFrom.publicKey.toBase58()}`);
            }
        }

        if (fullIntructions.length === 0) {
            console.log('No tokens to send');
            return [];
        }
        try {
            await getAccount(connection, receiverTokenAccount, 'processed');
        } catch (error) {
            //if (error instanceof TokenAccountNotFoundError || error instanceof TokenInvalidAccountOwnerError) {
            const instruction = await getCreateTokenAccountInstructions(fullIntructions[0].signer, tokenMint, receiver);
            fullIntructions.unshift({ instruction: instruction, signer: fullIntructions[0].signer });
            //   }
        }

        console.log(`Full instructions: ${fullIntructions.length}`);
        const versionedTransactions: VersionedTransaction[] = [];
        const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();

        console.log(`Full instructions: ${fullIntructions.length}`);
        for (let i = 0; i < fullIntructions.length; i += instructionsPerTransaction) {
            const transactionFullInstructions = fullIntructions.slice(i, i + instructionsPerTransaction);
            const allTransactionSigners = transactionFullInstructions.map((i) => i.signer);
            const message = new TransactionMessage({
                payerKey: allTransactionSigners[0].publicKey,
                recentBlockhash: blockhash,
                instructions: transactionFullInstructions.map((i) => i.instruction),
            }).compileToV0Message();
            const versionedTransaction = new VersionedTransaction(message);
            versionedTransaction.sign(allTransactionSigners);
            versionedTransactions.push(versionedTransaction);

            if (
                versionedTransactions.length === transactionsPerBundle ||
                i + instructionsPerTransaction >= fullIntructions.length
            ) {
                const id = await sendBundle(connection, versionedTransactions, allTransactionSigners[0], jitoTipLamports);
                logMessage(`Bundle sent: ${id}`, 'info', 'jito', id);
                console.log(`Bundle sent: ${id}`);
                const signature = bs58.encode(versionedTransactions[0].signatures[0]);
                await connection.confirmTransaction(
                    { blockhash: blockhash, lastValidBlockHeight: lastValidBlockHeight, signature: signature },
                    'confirmed',
                );
                versionedTransactions.length = 0;
            }
        }
    }

    async function getTokenDecimals(connection: Connection, publicKey: PublicKey) {
        const token = await getMint(connection, publicKey);
        return token.decimals;
    }

    return {
        getWalletsTokenStringBalances,
        sendTokensFromAllWalletsToReceiver,
        findAssociatedTokenAddress,
        getCreateAssociatedTokenAccountInstruction,
        checkAssociatedTokenAcount,
        getWalletTokenAccounts,
        getTokenDecimals,
    };
};
